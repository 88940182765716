<template>
  <section id="services" class="overflow-hidden">
    <v-row class="accent" no-gutters>
      <v-col class="hidden-sm-and-down" md="6">
        <v-img :src="require(`@/assets/photos/aws-group.jpg`)" height="100%" />
      </v-col>

      <v-col class="text-center pa-5" cols="12" md="6">
        <v-row class="fill-height" align="center" justify="center">
          <v-col cols="12">
            <base-bubble-2
              style="transform: translateX(55%)"
              :maxHeight="100"
            />
            <base-heading class="info--text">
              Top qualities
            </base-heading>

            <base-text class="mb-5">
              What I can bring...
            </base-text>
          </v-col>

          <v-col
            v-for="(service, i) in services"
            :key="i"
            class="text-center mb-3"
            md="6"
          >
            <v-avatar class="elevation-6 mb-2" color="#69A1BB" size="64" tile>
              <v-icon dark size="52" v-text="service.icon" />
            </v-avatar>

            <base-text>
              <div class="mb-2" v-text="service.name" />
              <div v-html="service.blurb" />
            </base-text>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  name: "Services",

  data: () => ({
    services: [
      {
        name: "Team player",
        icon: "mdi-microsoft-teams"
      },
      {
        name: "Eager to learn",
        icon: "mdi-head-question-outline"
      },
      {
        name: "Clean Code",
        icon: "mdi-code-braces"
      },
      {
        name: "Creative",
        icon: "mdi-screw-round-top"
      },
      {
        name: "Card magician",
        icon: "mdi-card-account-details-star-outline"
      }
    ]
  })
};
</script>
