





































































































































import Vue from "vue";
import { dataSkills } from "@/models/Types/Skills/DataSkills";
import { Skill } from "@/models/Types/Skills/Skill";

export default Vue.extend({
  props: {
    readMore: Boolean,
    full: Boolean
  },
  async mounted() {
    this.skills = await dataSkills;
  },
  data() {
    const skills: Skill[] = [];
    return {
      skills: skills,
      age: new Date().getFullYear() - 1995
    };
  }
});
