














// @ is an alias to /src

import Intro from "@/components/Pages/Home/Components/Intro.vue";
import Services from "@/components/Services.vue";
import RecentProjects from "@/components/Pages/Home/Components/RecentProjects.vue";
import WhatIamGoodAt from "@/components/Pages/About/WhatIamGoodAt.vue";
export default {
  name: "Home",
  components: { WhatIamGoodAt, Services, RecentProjects, Intro }
};
