










































































import { dataProjects } from "@/models/Types/Post/DataProjects";
import { Post } from "@/models/Types/Post/Post";
import Vue from "vue";

export default Vue.extend({
  name: "RecentProjects",
  async mounted() {
    const projects = await dataProjects;
    this.projects = projects.slice(0, 4);
  },
  data() {
    const projects: Post[] = [];
    return { projects };
  }
});
